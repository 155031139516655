import React, { useEffect, useState } from 'react';

import api from '../../services/api';

import { FiPlay } from 'react-icons/fi';

import { Container, BookRating } from './styles';

import { IBooksProps } from '../../interfaces/Book';
import { Star } from 'phosphor-react';
import { API_S3 } from '../../services/api_s3';

const CardBookHighlight: React.FC = () => {

  const [currentBook, setCurrentBook] = useState<IBooksProps>({} as IBooksProps);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/books/suggestions/last`);
        setCurrentBook(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
      <Container to={`/sasabooks/${currentBook?.id}`} cover={`${API_S3}/${currentBook?.cover}`}>
        <BookRating>
          <aside>
            <Star size={20} weight="fill" />
            <p>{Number(String(currentBook?.vote_average)).toFixed(1)}</p>
          </aside>
        </BookRating>
        <main>
          <div>
            <FiPlay strokeWidth={2.5} size={24}  />
          </div>
          <footer>
            <h3>{currentBook?.name}</h3>
          </footer>
        </main>
      </Container>
  );
}

export default CardBookHighlight;
