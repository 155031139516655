import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ICardBookProps {
  cover: string;
}

export const Container = styled(Link)<ICardBookProps>`
  position: relative;
  text-decoration: none;
  height: 100%;
  overflow: hidden;

  > main {
    position: relative;
    background-image: url(${props => props.cover });
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100%;
    background-position: bottom center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: ${props => props.theme.shadows.primary};
    transition: all .2s ease-in-out;
    background-size: cover;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      inset: 0px;
      background-image: linear-gradient(to bottom, transparent 10%, #021614 80%);
      opacity: 0.5;
      transition: all .3s ease-in-out 0s;
      border-radius: 7px;
    }

    &:hover {

      > footer {
        transform: translateY(-40px);
      }

      &::before {
        content: "";
        position: absolute;
        inset: 0px;
        opacity: 1;
        transition: all 0.3s ease-in-out 0s;
        border-radius: 0;
        border-radius: 7px;
      }

      > div {
        background: ${props => props.theme.colors.primary};
        border: 4px solid #ffffff;
        color: #ffffff;
      }
    }

    > div {
      width: 64px;
      height: 64px;
      border: 4px solid #ffffff77;
      color: #ffffff77;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      cursor: pointer;
      transition: all .3s ease-in-out;
      position: absolute;
      top: 40%;

      &:hover {
        background: ${props => props.theme.colors.primary};
        border: 4px solid #ffffff;
        color: #ffffff;
      }
      /* TABLET */
      @media (max-width: 768px) {
        transform: scale(.8);
      }
      /* MOBILE */
      @media (max-width: 480px) {
        transform: scale(.6);
      }

      > svg {
        margin-left: 4px;
      }
    }

    > footer {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: .5rem;
      line-height: 1;
      padding: 1rem;
      height: 80px;
      transition: all .2s ease-in-out;
      z-index: 1;
      bottom: -50px;
  
      > h3 {
        color: #fff;
        font-size: 14px;
      }
    }
  }

  > header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: red;
  }

  @media (max-width: 768px) {
    height: 320px;
    width: 200px;

    > main {
      background-position: center center;
    }
  }

  @media (max-width: 425px) {
    width: 100%;
    height: 550px;
  }
`;

export const ModalBooksDetails = styled.div`
  display: flex;
  margin: -32px -53px;
  overflow: hidden;

  > main {
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: center center;
    background-size: cover;
    overflow: hidden;

    &:after {
      content: '';
      background: #21114780;
      position: absolute;
      width: 110%;
      height: 110%;
      left: -15px;
      top: -15px;
      backdrop-filter: blur(5px);
      border-radius: 10px;
    }

    > section {
      z-index: 999;
    }
  }

`;

export const BookRating = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  z-index: 1;

  > aside {
    position: relative;
    display: flex;
    align-self: center;
    justify-content: center;
    color: #fff;

    > p {
      font-size: 12px;
      line-height: 2;
      display: flex;
      margin-left: 8px;
    }
  }

  > main {
    position: relative;
  }
`;
